
.wrapper[data-v-37b432dc] {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    min-height: 100vh;
    max-width: 100vw;
    padding: 10px;
    width: 100vw;
    height: 100vh;
}
.bg-active[data-v-37b432dc] {
    background-color: #232C3A;
    color : white !important;
}

